.logo-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  // Override tinyslider style.
  .tns-horizontal.tns-subpixel > &.tns-item {
    display: flex;
  }

  .tns-horizontal.tns-carousel.tns-no-subpixel > &.tns-item {
    margin: 0 !important;
  }

  &__inner {
    display: grid;
    justify-content: center;
    text-align: center;
  }

  &__media-holder {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 160px;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__media-element {
    display: block;
    max-height: 100%;
    max-width: 160px;
    object-fit: contain;
    object-position: center center;
  }

  &__title {
    @include font-weight(medium);
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 0;

    @include mq(tablet) {
      font-size: 18px;
      line-height: 32px;
    }
  }

  a.logo-card__title {
    color: $brand-blue;
    text-decoration: none;
  }

  &__intro {
    @include font-weight(light);
    font-size: 13px;
    line-height: 23px;

    @include mq(tablet) {
      font-size: 18px;
      line-height: 32px;
    }

    span {
      @include font-weight(light);
      display: block;
    }
  }
}
