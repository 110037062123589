.card--stat {
  @include border-radius(main);
  max-width: 306px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  .card__inner {
    @include border-radius(main);
    display: flex;
    border: 1px solid $brand-black-10;
  }

  .card__content-external {
    background: transparent;
  }

  .card__content-internal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .card__line1 {
    font-family: $brand-font-primary;
    @include font-weight(bold);
    @include letter-spacing(-20);
    font-size: 56px;
    color: $brand-orange;

    span {
      @include font-weight(bold);
    }
  }

  .card__line2 {
    font-family: $brand-font-primary;
    @include font-weight(bold);
    @include letter-spacing(-20);
    font-size: 18px;
    color: $brand-black;
    padding-bottom: 30px;
    text-transform: uppercase;

    @include mq(tablet) {
      font-size: 24px;
    }

    span {
      @include font-weight(bold);
    }
  }

  .card__image {
    max-width: 250px;
    margin-bottom: 40px;
  }
}

.stat-three-col {
  text-align: center;

  .stat-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .card {
    min-width: 240px;
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 10px;
  }
}

.stat-case-study {
  &__text {
    margin-top: 30px;

    @include mq(tablet) {
      text-align: right;
    }
  }
}
