.nav-testimonial__media-holder {
  @include mq($until: laptop-s) {
    display: none;
  }
}

.testimonial-carousel {
  width: 100%;

  .tns-slider {
    min-height: 508px;
  }

  .tns-outer {
    // Prevent brief flash of other slides on transition
    overflow: hidden;
  }

  .ktc-widget-body-wrapper & {
    .grid-column {
      display: none;

      &:first-of-type {
        display: block;
      }
    }

    .testimonial-carousel-nav {
      display: none;
    }
  }

  .grid-column {
    display: none;

    &:first-child {
      display: block;
    }

    &.tns-item {
      display: block;
    }
  }

  .testimonial-carousel-nav {
    display: grid;
    gap: 26px;
    // force 3 cols on mobile
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;

    @include mq(mobile-l) {
      grid-template-columns: repeat(auto-fit, minmax(20px, 92px));
    }

    &.is-playing {
      .testimonial-carousel-nav__item {
        display: flex;
      }
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      height: 0;
      justify-content: center;
      overflow: hidden;
      padding-bottom: 100%;
      width: 100%;

      .testimonial-carousel-nav__item-circles {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        border: 2px solid $brand-orange;
        border-radius: 50%;
        height: 100%;
        justify-content: center;
        opacity: 0;
        transform: scale(0.5);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        width: 100%;

        svg {
          @include radial-timer(46);
          transform: rotate(-90deg);
          animation-play-state: paused;
        }
      }

      .testimonial-carousel-nav__item-image {
        position: absolute;
        top: 5px;
        left: 5px;
        display: block;
        border-radius: 50%;
        height: calc(100% - 10px);
        object-fit: contain;
        object-position: center center;
        overflow: hidden;
        width: calc(100% - 10px);
        z-index: $layer-canvas;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(tablet) {
          display: block;
        }
      }

      &.tns-nav-active {
        .testimonial-carousel-nav__item-circles {
          opacity: 1;
          transform: scale(1);

          svg {
            @include anim-radial-timer(5s);
            animation-play-state: paused;
          }
        }
      }
    }

    &.is-playing {
      .testimonial-carousel-nav__item,
      .testimonial-carousel-nav__item.tns-nav-active {
        .testimonial-carousel-nav__item-circles {
          svg {
            animation-play-state: running;
          }
        }
      }
    }
  }
}
