/**
 * Actual @font-face should be added to CSS files in this folder.
 * They are then loaded in init-webfonts.js.
 */
@font-face {
  font-family: 'GT Walsheim';
  src: url('https://download.kentico.com/Assets/GT-Walsheim-Light.woff');
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('https://download.kentico.com/Assets/GT-Walsheim-Regular.woff');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('https://download.kentico.com/Assets/GT-Walsheim-Medium.woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('https://download.kentico.com/Assets/GT-Walsheim-Bold.woff');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
