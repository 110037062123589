// styles relating to portrait type cards
.card-portrait {
  width: 100%;
  background-color: $brand-white;

  .card-portrait__image {
    width: 100%;
    height: 215px;

    img {
      @include object-fit(cover, center);
    }
  }
}
