@import '../../settings/_vars.scss';

.form {
  @include border-radius(main);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  color: $brand-white;

  @include mq($until: tablet) {
    width: 100%;
  }

  @include mq(tablet) {
    padding-top: 40px;
    padding-right: 50px;
    padding-bottom: 40px;
    padding-left: 50px;
  }

  @include mq(desktop-s) {
    padding-top: 80px;
    padding-right: 100px;
    padding-bottom: 80px;
    padding-left: 100px;

    &--small {
      padding: 80px 50px;
      // Reduced to match the Kontent.ai form container
      max-width: 448px;
      margin: 0 auto;
    }
  }

  > div {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  h3 {
    text-align: center;
  }

  p {
    font-size: 14px;
    text-align: center;
  }

  .btn,
  input[type='submit'] {
    display: block;
    margin: 0 auto 20px;
    white-space: normal;

    @include mq(tablet) {
      margin-bottom: 0;
      transform: translateY(20px);
    }
  }

  .field-validation-error {
    color: $form-error-text;
    border-color: $form-error-text;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    padding: 2px 0;
    display: inline-block;
    font-weight: 400;
  }
  // Phone Valid mesasge
  .field-validation-valid.phone-msg--valid:not(.phone-msg-hide) {
    color: $brand-white;
    font-size: 14px;
    line-height: 24px;
    margin-top: 4px;
    padding: 2px 0;
    color: $brand-green;
    display: inline-block;
    font-weight: 400;
  }

  .input-validation-error,
  .phone-input-error {
    color: $form-error-text;
    border-color: $form-error-text;
    border-width: 2px;
  }

  @include mq(tablet) {
    .ktc-two-columns-section {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 24px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, auto);
    }
  }
}

@include mq(tablet) {
  // Container query
  @container (max-width: 360px) {
    .form {
      padding-right: 32px;
      padding-top: 32px;
      padding-left: 32px;

      .ktc-two-columns-section {
        display: block;
      }
    }
  }

  @container (max-width: 290px) {
    .form {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}

.form-input-container {
  & > & {
    margin: 12px 0 0;
  }

  &.form-input-container--error {
    .form-label {
      &.form-label--error {
        display: block;
        color: hsla(0, 63%, 75%, 1);
      }
    }
  }
}

.form-input-container,
.form-field {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;

  &.hidden {
    display: none;
  }

  .form-label,
  .control-label {
    font-size: 16px;
    line-height: 24px;
    color: $brand-black;
    order: -1;

    &.form-label--error {
      display: none;
      order: 3;
      align-items: center;
      margin-top: 8px;
      position: relative;

      &:before {
        width: 16px;
        height: 16px;
        content: '';
        background-image: url('../../../wwwroot/src/img/svg/icon-error.svg');
        background-size: 16px 16px;
        position: absolute;
        top: -41px;
        left: 15px;
      }
    }
  }

  .explanation-text {
    margin: 10px 0 0;
    color: $brand-white;
    font-size: 12px;
  }
}

.form-input-container--clickable {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;

  .form-label {
    order: 2;
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding-top: 2px;
    padding-left: 40px;
    transition: color 0.3s ease-out;
    text-align: left;

    &:before {
      width: 24px;
      height: 24px;
      content: '';
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 24px;
      border: 1px solid $brand-black-50;
      flex-shrink: 0;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
      position: absolute;
      top: 1px;
      left: 0;
    }

    &:after {
      width: 12px;
      height: 12px;
      content: '';
      position: absolute;
      top: 8px;
      left: 7px;
      background-color: $brand-white;
      border-radius: 12px;
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    }
  }
}

.form-input-container--consentagreement {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  justify-content: center;

  .form-label {
    margin-bottom: 10px;
  }

  .form-caption {
    display: block;
    color: $brand-white;
    font-size: 0.8em;
    line-height: 1.25em;
    margin-left: 40px;
    width: 100%;

    a {
      color: $brand-white;
      text-decoration: underline;

      &:focus,
      &:hover {
        outline: 1px solid currentColor;
      }
    }
  }
}

.bg-violet,
.bg-black,
.bg-orange,
.bg-blue {
  &.form p,
  &.form h3,
  .form-input-container .form-label,
  .form-field .control-label,
  .ktc-checkbox label,
  .ktc-radio label,
  .ktc-uploader-text,
  .ktc-uploader-replacement,
  .ktc-uploader-text-button,
  .formwidget-submit-text,
  a,
  a:visited,
  a:hover {
    color: $brand-white;
  }
}

.bg-violet,
.bg-blue {
  // Blue form button is a default
  // So the colours need to be switched just in certain cases
  input[type='submit'] {
    --color-bg: #{$brand-orange};
    --color-bg-hover: #{$brand-orange-60};
    --color-bg-active: #{$brand-orange-80};
    --color-text: #{$brand-white};
    --color-text-hover: #{$brand-white};
    --color-text-active: #{$brand-white};
    --color-border: #{transparent};
    --color-border-hover: #{$brand-orange-60};
    --color-border-active: #{$brand-orange-80};
  }
}

.bg-blue,
.bg-orange {
  .ktc-checkbox,
  .ktc-checkbox:hover {
    label {
      &:before {
        border-color: $brand-white;
      }
    }
  }

  .ktc-radio {
    .ktc-radio,
    .ktc-radio:hover {
      label {
        &:before {
          border-color: $brand-white;
        }
      }
    }
  }

  .ktc-checkbox input[type='checkbox'],
  .ktc-radio input[type='radio'] {
    &:checked {
      + label,
      + input + label {
        &:before {
          border-color: $brand-white;
          background-color: transparent;
        }
      }
    }
  }

  .field-validation-error {
    color: $brand-white;
  }
}

.bg-white {
  &.form {
    border: 1px solid $brand-black-25;
  }
}

.rc-anchor-container {
  transform: scale(0.92) translateX(-13px) !important;
}

.ktc-utilities-section {
  display: flex;
  flex-direction: column;

  .form-field {
    margin: 0;

    .editing-form-control-nested-control {
      & > .ktc-checkbox {
        margin-bottom: 32px;
        display: block;
      }
    }
  }
}
