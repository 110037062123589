@import '../settings/_vars.scss';

.card {
  &.card--reports {
    .card__content-external {
      transition: background-color 0.3s;
    }
  }

  &.card--reports.card--no-img {
    border-radius: 0;
    overflow: visible;

    .card__content-external {
      background-color: $brand-white;
    }

    &:hover {
      box-shadow: none;

      .card__content-internal {
        box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.card__media {
  .card--reports & {
    min-height: 240px;
    background-color: var(--color-bg);
  }

  .card--no-img & {
    min-height: 0;
  }
}

.card__media-element {
  .card--reports & {
    @include mq(tablet) {
      // Changed back to 240px due to KEN-474.
      height: 240px;
    }
  }

  .card--reports.card--small-ver & {
    @include mq(tablet) {
      height: 240px;
    }
  }
}

.card__title {
  .card--reports & {
    @include mq(tablet) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .card--small-ver & {
    font-size: 22px;
    line-height: 26px;
  }
}

.card--reports {
  .h2-styling.card__title,
  h2.card__title,
  .h3-styling.card__title,
  h3.card__title,
  .h4-styling.card__title,
  h4.card__title {
    &:after {
      font-size: 32px;
    }
  }
}

.card__report-logo {
  margin-bottom: 30px;
  max-width: 110px;

  @include mq(tablet) {
    max-width: 80%;
  }

  .card--small-ver & {
    @include mq(tablet) {
      max-width: 110px;
    }
  }
}