@mixin radial-timer($radius) {
  $pi: 3.14159265359;
  $circumference: $radius * 2 * $pi;

  stroke-dasharray: $circumference $circumference;
  stroke-dashoffset: $circumference;
}

@mixin font-weight($value) {
  font-weight: map-get($font-weight, $value);
}

@mixin fw($value) {
  @include font-weight($value);
}

@mixin border-radius($value) {
  border-radius: map-get($border-radius-value, $value);
}

@mixin br($value) {
  @include border-radius($value);
}

@mixin box-shadow($value: 0px 0px 30px 0px) {
  box-shadow: $value $brand-shadow;
}

@mixin bs($value: 0px 0px 30px 0px) {
  @include box-shadow($value);
}

@mixin letter-spacing($value) {
  letter-spacing: netc-character-spacing($value);
}

@mixin ls($value) {
  @include letter-spacing($value);
}

@mixin object-fit($of: cover, $op: top) {
  width: 100%;
  height: 100%;
  object-fit: $of;
  object-position: $op;
  font-family: 'object-fit: #{$of}; object-position: #{$op}';
}

@mixin helpers-reset-button {
  border: 0;
  background: transparent;
  font-weight: normal;
  padding: 0;
  outline: 0;
  cursor: pointer;
}

@mixin header-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    margin: 0;
    max-width: 450px;
  }
}

@mixin fade-mask($sp: 10%, $dir: to right) {
  mask-image: linear-gradient(
    $dir,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) $sp,
    rgba(0, 0, 0, 1) (100% - $sp),
    rgba(0, 0, 0, 0) 100%
  );
}

@mixin bullet-list-icon() {
  content: '';
  position: absolute;
  top: 0.675em;
  left: -20px;
  display: inline-block;
  background-color: $brand-orange;
  border-radius: 50%;
  height: 8px;
  transform: translateY(-50%);
  width: 8px;
}
