@import '../settings/_vars.scss';

$card-content-indent-sides: 30px;
$brand-checkbox-circle-transparent: rgba(0, 0, 0, 0.1);
$circle-indent-top: 16px;
$circle-indent-left: $card-content-indent-sides;
$tick-indent-top: $circle-indent-top + 3;
$tick-indent-left: $circle-indent-left + 8;
$label-text-padding-top: 20px;
$label-text-padding-left: 70px;
$checkbox-ribbon-height: 60px;

.card-uni {
  background-color: $card-bg;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @include border-radius(main);
  overflow: hidden;
  transition: $card-transition;

  @include mq(tablet) {
    height: calc(100% - 30px);
  }

  &:hover {
    background-color: $card-hover-bg;
  }

  &__media {
    height: 240px;

    &-element {
      @include object-fit(cover, center);
      width: 100%;
      height: 240px;
    }
  }

  &__voting-checkbox + &__media {
    margin-top: $checkbox-ribbon-height;
  }

  &__content {
    position: relative;
    padding: 20px $card-content-indent-sides;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    transition: $card-transition;

    @include mq(tablet) {
      padding-bottom: 0;
    }

    &:last-child {
      padding-bottom: 24px;
    }
  }

  &__tag {
    margin-top: -16px;
    margin-left: 30px;
    z-index: 2;

    & + .card-uni__length-time {
      margin-top: -56px;
    }
  }

  &__title {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 26px;
  }

  &__intro {
    color: $brand-black;
    margin-bottom: 16px;
    line-height: 24px;
    font-size: 16px;
  }

  &__award {
    margin-bottom: 16px;
  }

  &__middle-band {
    display: flex;
    padding: 48px 30px 8px 30px;
    margin-top: -18px;

    &-text {
      @include font-weight(bold);
      font-size: 22px;
      line-height: 28px;
      color: $brand-violet-80;
      margin-bottom: 0;
      align-self: center;
    }
  }

  &__content-bottom {
    font-size: 16px;
    margin-top: auto;
  }

  &__date {
    font-size: 16px;
    align-self: flex-start;
    color: $brand-black-50;
    margin-bottom: 16px;
  }

  &__external-link {
    display: inline-block;
    @include font-weight(medium);
    color: $brand-orange;
    font-size: 16px;
    margin-bottom: $paragraph-margin-bottom;

    .icon-svg {
      stroke: $brand-orange;
      transform: translateY(-1px) rotate(0);
      width: 16px;
      height: 10px;
      vertical-align: middle;
    }
  }

  &__author {
    margin-bottom: 16px;
    color: $brand-black-50;
  }

  &__length-time {
    font-size: 12px;
    font-weight: bold;
    padding: 4px 6px;
    @include border-radius(small);
    background: $brand-black-70;
    color: $brand-white;
    align-self: flex-end;
    margin-top: -36px;
    margin-bottom: 30px;
    margin-right: 8px;
  }

  &__icon-content {
    display: grid;
    grid-template-columns: 50px 1fr;
    color: $brand-text;
    font-size: 14px;

    @include mq(tablet) {
      font-size: 16px;
    }

    &-title {
      align-self: center;
      @include font-weight(medium);
      display: block;
      font-size: 14px;

      @include mq(tablet) {
        font-size: 16px;
      }
    }
  }

  &__inner {
    position: relative;
  }
  // SOTY
  &--soty-website {
    box-shadow: none;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: $card-hover-shadow;
    }

    .card-uni__content-bottom {
      z-index: 4;
    }
  }

  &__voting-checkbox {
    position: absolute;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;

    &:before {
      content: "";
      position: absolute;
      min-height: $checkbox-ribbon-height;
      width: 100%;
      background-color: var(--color-bg);
      transition: all 0.3s ease-in-out;
    }
    // hide input visually
    &-input {
      height: 40px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 40px;

      &:checked {
        & + label {
          padding-top: $label-text-padding-top - 2;
          padding-left: $label-text-padding-left - 2;
          border: 2px solid $brand-green;
          color: $brand-black;
          cursor: initial;
        }
        // circle
        & + label:before {
          background: $brand-green;
          border-color: $brand-green;
          display: block;
          left: $circle-indent-left - 2;
          top: $circle-indent-top - 2;
        }
        // tick
        & + label:after {
          opacity: 1;
        }
      }

      &[disabled] + label {
        cursor: auto;
      }
    }

    &-label {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: relative;
      display: flex;
      align-items: flex-start;
      padding-top: $label-text-padding-top;
      padding-left: $label-text-padding-left;
      padding-right: 20px;
      @include border-radius(main);
      color: $brand-black;
      // circle
      &:before {
        border: 1px solid $brand-black-20;
        content: "";
        height: 24px;
        width: 24px;
        left: $circle-indent-left;
        top: $circle-indent-top;
        border-radius: 50%;
        display: block;
        position: absolute;
      }
      // tick
      &:after {
        display: block;
        content: "";
        color: $brand-white;
        border: 2px solid;
        border-left: 0;
        border-top: 0;
        width: 5px;
        height: 12px;
        left: $tick-indent-left;
        top: $tick-indent-top;
        opacity: 0;
        transform: rotate(45deg);
        transition: opacity 0.2s ease-in-out;
        position: absolute;
      }
    }
  }
}
