.card-50-50__inner {
  // needed to stop horizontal scroll bar appearing before cards have animated in.
  overflow: hidden;

  @include mq(laptop-s) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
  }
}

.card-50-50__media-holder {
  align-self: center;
  margin-bottom: 30px;
  order: 0;

  @include mq(laptop-s) {
    margin-bottom: 0;
  }

  .card-50-50--flipped & {
    @include mq(laptop-s) {
      order: 1;
    }
  }
}

.card-50-50__media-holder-inner {
  @include mq(tablet) {
    text-align: center;
  }

  @include mq(laptop-s) {
    text-align: left;
  }

  .card-50-50--image-centered & {
    @include mq(laptop-s) {
      display: flex;
      justify-content: center;
    }
  }

  .card-50-50--flipped & {
    @include mq(laptop-s) {
      text-align: right;
    }
  }
}

.card-50-50__media-element {
  max-width: 100%;

  @include mq(tablet) {
    width: auto;
    max-height: 300px;
  }

  @include mq(laptop-s) {
    max-height: 100%;
  }

  &--original {
    @include mq(tablet) {
      height: 300px;
    }
  }
}

.card-50-50__content-holder {
  order: 1;
  align-self: center;
  justify-self: flex-start;

  .card-50-50--flipped & {
    @include mq(laptop-s) {
      order: 0;
      justify-self: flex-end;
    }
  }
}

.card-50-50__content-holder-inner {
  @include mq(laptop-s) {
    padding-left: 30px;
  }

  .card-50-50--flipped & {
    @include mq(laptop-s) {
      padding-left: 0;
      padding-right: 30px;
    }
  }

  .tag {
    margin-bottom: 25px;
  }
}

.card-50-50__title {
  margin-bottom: 24px;
}

.card-50-50__intro {
  margin-bottom: 24px;
}

.card-50-50__partners {
  margin-top: 40px;

  @include mq(tablet) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 25px;
  }
}

.card-50-50__partners-item {
  display: grid;
  grid-template-columns: 70px auto;
  margin-bottom: 20px;

  @include mq(tablet) {
    margin-bottom: 0;
  }
}

.card-50-50__partners-logo {
  align-self: center;
}

.card-50-50__partners-content {
  margin-bottom: 0;
  align-self: center;

  span {
    display: block;
  }
}

.card-50-50 {
  img:not(.card-50-50__media-element--original) {
    @include border-radius(cardImage);
  }

  .video-card__media-element,
  .card-50-50__media-element--sharp-corners {
    border-radius: 0;
  }
}
