// DATE STYLES

.card__date-block {
  position: absolute;
  bottom: 0;
  right: 0;
  display: grid;
  grid-template-rows: auto auto auto;

  .card__event-date-area & {
    position: relative;
    bottom: auto;
    right: auto;
    max-width: 75px;
  }

  .card--no-img & {
    bottom: auto;
    z-index: 1;
  }
}

.card__date-block-month {
  @include font-weight(bold);
  @include letter-spacing(100);
  text-transform: uppercase;
  font-size: 11px;
  background-color: $brand-text;
  color: $brand-white;
  padding: 2px 15px;
  text-align: center;

  .card__event-date-area & {
    background-color: $brand-green;
  }
}

.card__date-block-day {
  @include font-weight(bold);
  @include letter-spacing(-10);
  text-transform: uppercase;
  font-size: 28px;
  background-color: $brand-white;
  color: $brand-text;
  text-align: center;

  .card__event-date-area & {
    font-size: 24px;
  }
}

.card__date-block-year {
  @include font-weight(bold);
  @include letter-spacing(100);
  text-transform: uppercase;
  font-size: 11px;
  background-color: $brand-purple;
  color: $brand-white;
  padding: 2px 15px;
  text-align: center;

  .card__event-date-area & {
    background-color: $brand-black-50;
  }
}