.feature-card {
    text-decoration: none;
    padding-left: 35px;
    display: block;

    @include mq(tablet) {
      padding-left: 50px;
    }

    @include mq(laptop-s) {
      margin-left: 20%;
    }

    &__title {
      margin-bottom: 24px;
    }

    .fake-link {
        font-size: 14px;
        line-height: 16px;

        @include mq(tablet) {
            font-size: 16px;
            line-height: 18px;
        }
    }
}

.feature-card__title-area {
    position: relative;
}

.feature-card__icon {
    position: absolute;
    top: 3px;
    left: -32px;
    max-width: 20px;

    @include mq(tablet) {
        top: 7px;
        left: -48px;
        max-width: 24px;
    }
}

.feature-card__intro {
    color: $brand-text;
    margin-bottom: 24px;
    line-height: 1.75em;
}