.card-testimonial,
.nav-testimonial {

  &__inner {
    @include mq(tablet) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 25px;
    }
  }

  &__media-inner {
    text-align: center;

    @include mq(tablet) {
      padding: 0 25% 0 15%;
      text-align: left;
    }
  }

  &__media-element {
    max-width: 100%;
  }

  &__content-holder {
    padding-bottom: 100px;
    position: relative;

    @include mq(tablet) {
      padding-bottom: 140px;
    }

    .icon-quote {
      fill: $brand-sky-blue;
      stroke: none;
      margin: 0 0 8px;
      width: 34px;
      display: block;

      @include mq(tablet) {
        left: -50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0;
      }

      @include mq(laptop-s) {
        width: 57px;
        left: -80px;
      }
    }
  }

  &__testimonial-lead {
    @include font-weight(bold);
    font-size: 22px;
    line-height: 26px;
    position: relative;
    margin-bottom: .75em;

    @include mq(laptop-s) {
      font-size: 32px;
      line-height: 40px;
    }

    &:after {
      content: '.';
      color: $brand-black;
      font-size: 32px;

      @include mq($until: mobile-l) {
        font-size: 22px;
      }
    }
  }

  &__testimonial-intro {
    @include font-weight(regular);
    line-height: 32px;
    font-size: 16px;

    @include mq(laptop-s) {
      font-size: 18px;
    }
  }

  &__testimonial-author {
    @include font-weight(bold);
    @include letter-spacing(20);
    font-size: 18px;
    line-height: 22px;
  }

  &__logo-element {
    max-height: 65px;
    width: auto;
  }
}

.nav-testimonial {
  min-height: 50px;

  @include mq(laptop-s) {
    min-height: 100px;
  }

  &__content-holder {
    padding-top: 0;
    padding-bottom: 0;

    @include mq(tablet) {
      padding-bottom: 0;
    }
  }
}
