@import '../settings/_vars.scss';

.footer {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  background-color: $brand-sky-blue-10;
  font-size: 16px;
  line-height: 20px;
  @include letter-spacing(20);
  color: $brand-text;
  text-decoration: none;
  @include font-weight(regular);

  @include mq(mobile-l) {
    padding-top: 32px;
  }

  &__circle {
    width: 1000px;
    height: 1000px;
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.4);
    transform-origin: top right;
    background-image: url('../../../wwwroot/src/img/svg/bg-violet-top-right.svg');
    background-size: 355px auto;
    background-position-x: right;
    background-repeat: no-repeat;
    display: none;

    @include mq(mobile-l) {
      display: block;
    }

    @include mq(laptop-m) {
      transform: translate(18%, 0);
      transform: scale(0.5);
    }

    @include mq(desktop-s) {
      transform: translate(0, -10%);
    }
  }

  &__circle--small {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 500px;
    left: -160px;
    background-color: $brand-violet;
    border-radius: 50%;
    display: none;

    @include mq(tablet) {
      display: block;
    }

    @include mq(desktop-s) {
      left: -100px;
    }
  }

  p,
  a:not(.btn) {
    font-size: 16px;
    line-height: 20px;
    @include letter-spacing(20);
    color: $brand-text;
    text-decoration: none;
    @include font-weight(regular);
  }

  .footer__top {
    position: relative;
    margin-bottom: 42px;

    @include mq(mobile-l) {
      margin-bottom: 64px;
    }

    @include mq(laptop-m) {
      .grid-column {
        grid-column: 2 / span 1;
      }
    }

    svg {
      display: block;
      height: 52px;
    }

    p {
      margin-top: 22px;
      @include font-weight(bold);
    }
  }

  .footer__middle {
    margin-bottom: 16px;
    color: $brand-text;

    @include mq(laptop-m) {
      margin-bottom: 36px;
    }

    .grid-column {
      @include mq($until: tablet) {
        margin-bottom: 24px;
      }
    }

    p:last-of-type {
      margin-bottom: 54px;
    }

    .footer__social {
      display: flex;
      margin-top: 16px;

      @include mq(laptop-m) {
        justify-content: flex-end;
        align-items: flex-end;
      }

      .footer__social-link {
        height: 25px;
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 12px;

        @include mq(tablet) {
          margin: 0 0 0 12px;
        }

        a {
          display: block;
          line-height: 0;

          &[href*="facebook.com"] {
            path {
              fill: #1877F2;
            }
          }

          &[href*="youtube.com"] {
            path {
              fill: #FF0302;
            }
          }

          &[href*="twitter.com"] {
            path {
              fill: #000000;
            }
          }

          &[href*="linkedin.com"] {
            path {
              fill: #0A66C2;
            }
          }
        }
      }
    }

    .footer__rating-items {
      display: flex;
      flex-direction: row;

      & > * {
        padding-left: 8px;
        padding-right: 8px;
      }

      @include mq(tablet) {
        padding-top: 16px;
      }

      @include mq(laptop-m) {
        flex-direction: column;
        padding-top: 0;

        & > * {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .footer__rating {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include mq(laptop-m) {
        justify-content: flex-end;
        align-items: flex-end;
        grid-column: span 2;
      }

      &-logo {
        transition: all .2s ease-in-out;

        &--g2 {
        }

        &--gartner-peer {
          opacity: .8;

          &:hover {
            opacity: 1;
          }
        }
      }

      &-link {
        display: block;
        position: relative;

        & + .footer__rating-link {
          margin-top: 16px;
        }
      }
    }
  }

  .footer__bottom {
    padding-bottom: 16px;

    .grid-column {
      padding-top: 16px;
      border-top: 0.5px solid transparent;

      @include mq(tablet) {
        padding-top: 36px;
      }
    }

    p {
      font-size: 14px;
    }
  }

  .footer__copyright {
    text-align: center;

    @include mq(mobile-l) {
      text-align: left;
    }
  }

  .footer__nav {
    + .footer__nav {
      margin-top: 70px;

      @include mq(tablet) {
        margin-top: 54px;
      }
    }

    a {
      transition: color 0.3s ease-in-out;
      position: relative;

      &:hover {
        color: $brand-orange;
      }

      &:active {
        text-decoration: underline;
      }
    }

    li {
      display: block;

      &:first-child {
        a,
        span {
          @include font-weight(bold);
        }

        span {
          cursor: default;
        }
      }

      & + li {
        margin-top: 16px;
      }
    }

    &--aux {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          width: 100%;
          text-align: center;

          @include mq(mobile-l) {
            width: auto;
          }

          + li {
            margin-top: 0;

            @include mq(mobile-l) {
              margin-left: 16px;
            }

            @include mq(laptop-m) {
              margin-left: 30px;
            }
          }

          &:first-child {
            a {
              @include font-weight(regular);
            }
          }

          @include mq($until: mobile-l) {
            min-width: 33%;
          }

          @include mq($until: mobile-m) {
            min-width: 50%;
          }
        }

        a {
          font-size: 14px;
          padding: 8px 0;
          display: inline-block;

          @include mq(mobile-l) {
            padding: 0;
          }
        }
      }
    }
  }

  .footer__logo {
    max-width: 150px;
  }

  .footer__products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    .footer__product-item {
      display: flex;
      align-items: flex-start;
      width: 66%;
      padding: 30px 0;

      @include mq(tablet) {
        min-width: 320px;
      }

      @include mq($until: laptop-m) {
        width: 100%;
      }

      &:first-child {
        padding: 0 0 30px 0;
        border-bottom: 1px solid transparent;
      }

      &--logo {
        width: 100%;
        max-width: 128px;
        min-width: 128px;
        margin-right: 50px;
      }

      &--content {
        color: $brand-white;
        font-size: 12px;
        @include font-weight(medium);
        max-width: 170px;
      }
    }
  }

  hr {
    border-top: 2px solid #d3dce5;
    height: 0;
  }
}
