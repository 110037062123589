@import '../settings/_vars.scss';
@import '../base/_typography.scss';

$tag-indent: 40px;
$title-indent: 16px;

.card--img-fit {
  &:hover {
    .card__content-external {
      background-color: $card-bg !important;
    }
  }

  h2.card__title {
    @extend .h2-styling;
    margin-bottom: $title-indent;
  }

  h3.card__title {
    @extend .h3-styling;
    margin-bottom: $title-indent;
  }

  h4.card__title {
    @extend .h4-styling;
    margin-bottom: $title-indent;
  }

  .card__media {
    min-height: initial;

    &-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-element {
      height: 100%;
      max-height: 300px;
      border-radius: initial;
      object-fit: contain;
    }
  }

  .card__content-internal {
    padding-top: 8px;
  }

  .tag {
    position: relative;
    left: 0;
    top: 0;
    transform: translateY(0);
    margin-top: $tag-indent;
    margin-left: $tag-indent;
    padding-left: 32px;
    padding-right: 32px;

    &--transparent {
      background: none;
      color: $brand-violet;
      border: 1px solid $brand-violet;
    }
  }

  .card__footer {
    .btn {
      margin-top: 24px;
    }
  }

  @include mq(tablet) {
    &.card--landscape {
      .card__inner {
        display: flex;
      }
    }
  }

  &.card--landscape {
    .tag {
      display: none;

      @include mq(laptop-s) {
        display: inline-block;
      }

      &--mobile {
        display: inline-block;

        @include mq(laptop-s) {
          display: none;
        }
      }
    }

    @include mq(laptop-s) {
      .card__inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .card__media {
        order: 2;
      }

      .card__content-external {
        order: 1;
      }

      .card__media-holder {
        justify-content: center;
      }

      .tag {
        margin-top: 0;
      }
    }

    .card__media-element {
      width: auto;
    }
  }
}
