* {
  font-family: $brand-font-primary;
}

$font-weight: (
  light: 100,
  regular: 400,
  medium: 500,
  bold: 700,
);

h1,
.h1-styling,
h2,
.h2-styling,
h3,
.h3-styling,
h4,
.h4-styling,
h5,
.h5-styling,
h6,
.h6-styling {
  @include font-weight(bold);
  color: $brand-text;
  margin-bottom: 40px;

  .bg-black & {
    color: $brand-white;
  }
}

h1,
.h1-styling {
  font-size: 72px;
  line-height: 72px;

  &:after {
    content: '.';
    color: $brand-orange;
    font-size: 52px;

    @include mq($until: laptop-s) {
      font-size: 40px;
    }

    @include mq($until: mobile-l) {
      font-size: 22px;
    }
  }

  @include mq($until: laptop-s) {
    font-size: 56px;
    line-height: 56px;
  }

  @include mq($until: mobile-l) {
    font-size: 32px;
    line-height: 40px;
  }


  &.h2-styling {
    &:after {
      content: '.';
      color: $brand-orange;
      font-size: 40px;

      @include mq($until: tablet) {
        font-size: 22px;
      }
    }
  }

  &.h3-styling {
    &:after {
      content: '.';
      color: $brand-orange;
      font-size: 32px;

      @include mq($until: tablet) {
        font-size: 22px;
      }
    }
  }

  &.no-orange-full-stop,
  .no-orange-full-stop &,
  .fr-view & {
    &:after {
      content: none;
    }
  }
}


h2,
.h2-styling {
  font-size: 56px;
  line-height: 56px;

  &:after {
    content: '.';
    color: $brand-orange;
    font-size: 52px;

    @include mq($until: laptop-s) {
      font-size: 40px;
    }

    @include mq($until: mobile-l) {
      font-size: 22px;
    }
  }

  @include mq($until: tablet) {
    font-size: 32px;
    line-height: 40px;
  }

  &.no-full-stop,
  .no-full-stop &,
  .fr-view & {
    &:after {
      content: none;
    }
  }

  .grid-container--six-two & {
    @include mq($until: desktop-s) {
      font-size: 40px;
      line-height: 46px;
    }
  }
}

h3,
.h3-styling {
  font-size: 32px;
  line-height: 40px;

  @include mq($until: tablet) {
    font-size: 22px;
    line-height: 26px;
  }
}

h4,
.h4-styling {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 24px;

  @include mq($until: tablet) {
    font-size: 18px;
    line-height: 24px;
  }
}

h5,
.h5-styling {
  font-size: 18px;
  line-height: 24px;

  @include mq($until: tablet) {
    font-size: 14px;
    line-height: 16px;
  }
}

h6,
.h6-styling {
  font-size: 14px;
  line-height: 16px;
}

p,
.card-50-50__intro,
.card__intro {
  font-size: 16px;
  line-height: 24px;
  @include font-weight(regular);
  margin-bottom: $paragraph-margin-bottom;

  @include mq(tablet) {
    font-size: 18px;
    line-height: 24px;
  }

  .intro-text &,
  &.intro-text {
    line-height: 32px;
    @include font-weight(medium);

    @include mq(tablet) {
      line-height: 32px;
    }
  }

  .meta-text & {
    font-size: 12px;
    line-height: 14px;
  }
}

.wysiwyg {
  max-width: 575px;

  .grid-container--four & {
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    li {
      list-style: disc;
    }
  }

  &--case-success {
    h4 {
      margin-bottom: 10px;
    }

    p {
      a {
        text-decoration: none;
        color: $brand-orange;
      }
    }

    p + ul {
      margin-top: -20px;
    }

    ul {
      margin-bottom: 28px;

      li {
        list-style: none;
        position: relative;
        padding-left: 20px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 24px;
        @include font-weight(regular);

        @include mq(tablet) {
          font-size: 18px;
        }

        &:before {
          width: 5px;
          height: 3px;
          display: block;
          content: '-';
          position: absolute;
          left: 0;
        }
      }
    }
  }
}

.chev-link {
  @include font-weight(medium);
  color: $brand-orange;
  text-decoration: none;
  position: relative;
  transition: .2s color;
  margin-bottom: 4px;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -3px;
    height: 1px;
    right: 20px;
    left: 0;
    transition: .2s background-color;
  }

  .icon-svg {
    transform: translateY(-1px) rotate(-90deg);
    stroke: $brand-orange;
    width: 16px;
    height: 10px;
    vertical-align: middle;
    transition: .2s stroke;
  }

  &:hover {
    &::after {
      background-color: $brand-orange;
    }
  }

  &:active {
    color: $brand-orange-60;

    &::after {
      background-color: $brand-orange-60;
    }

    .icon-svg {
      stroke: $brand-orange-60;
    }
  }
}

.fake-link {
  @include font-weight(medium);
  color: $brand-orange;
  position: relative;
  transition: .2s color;
  margin-bottom: 4px;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    right: 20px;
    left: 0;
    transition: .1s background-color;
  }

  &.fake-link--external {
    color: $brand-orange;

    .icon-svg {
      stroke: $brand-orange;
      transform: translateY(-1px) rotate(0);
    }
  }

  .icon-svg {
    transform: translateY(-1px) rotate(-90deg);
    stroke: $brand-blue;
    width: 16px;
    height: 10px;
    vertical-align: middle;
    transition: .2s stroke;
  }

  &:hover {
    &::after {
      background-color: $brand-orange;
    }
  }

  &:active {
    color: $brand-orange-60;

    &::after {
      background-color: $brand-orange-60;
    }

    .icon-svg {
      stroke: $brand-orange-60;
    }
  }
}

.indent-title {
  margin-bottom: 50px;

  @include mq(tablet) {
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: repeat(16, 1fr);
  }
}

.indent-title__title {
  margin-bottom: 30px;
  text-align: center;

  @include mq(tablet) {
    grid-column: 3 / span 12;
    text-align: center;
    align-self: center;
    margin-bottom: 0;
  }

  @include mq(laptop-s) {
    grid-column: 5 / span 8;
  }
}

.indent-title__btn-holder {
  text-align: center;
  @include mq(laptop-m) {
    grid-column: 12 / span 3;
    align-self: center;
    justify-self: center;
  }
}

.amount-counter {
  font-size: 16px;
}

.xperience-main {
  .search-result__term,
  .template-banner__author-name a,
  .accordion a,
  .card-50-50__intro a,
  p a {
    color: $brand-orange;
    transition: .2s color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: $brand-orange-80;
      text-decoration: underline;
    }
  }

  // Widget bar fix
  .cms-edit & {
    margin-top: 48px;
  }
}

.rta-intro {
  @include font-weight(medium);
  padding: 0 20px;
  display: block;

  @include mq(tablet) {
    padding: 0 40px;
  }
}