.card--content {
  &:hover {
    box-shadow: none;
    transform: none;
  }

  .card__content-external {
    background: transparent;
  }

  .card__title {
    font-size: 32px;
    line-height: 40px;
  }

  .card__content {
    font-size: 18px;
    line-height: 32px;
  }
}
