@import '../../settings/_vars.scss';

$form-input-top-indent: 12px;

.form-input,
.form-control {
  width: 100%;
  padding-top: 16px;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-left: 32px;
  margin-top: $form-input-top-indent;
  border: 1px solid $brand-black-25;
  border-radius: 4px;
  font-family: $brand-font-primary;
  font-size: 14px;
  color: $brand-black;
  line-height: 16px;
  @include letter-spacing(20);
  outline: 0;
  appearance: none;
  transition: border-color 0.3s ease-out, color 0.3s ease-out;
  resize: none;
  overflow: hidden;
  background-color: $brand-white;

  &--text,
  &[type='text'],
  &[type='email'],
  &[type='file'],
  &[type='tel'] {
    @include border-radius(input);
  }

  &--textarea {
    height: 96px;
  }

  &--select {
    background-image: url('../../../wwwroot/src/img/svg/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: calc(100% - 20px) center;
    @include border-radius(input);

    ~ .form-label--error {
      &:before {
        top: -42px;
      }
    }
  }

  &::placeholder {
    color: $brand-black-50;
  }

  &:focus {
    border-color: $brand-violet;
    color: $brand-black;
  }

  &[disabled] {
    color: $brand-black-20;
    border-color: $brand-black-20;
    background-color: transparent;
    cursor: not-allowed;

    + .form-label {
      color: $brand-black-20;
    }
  }

  &.form-input--error {
    border-color: $brand-red;
    padding-left: 42px;

    &::placeholder {
      opacity: 1;
      color: $brand-red;
    }

    ~ .form-label {
      display: block;
      color: $brand-red;
    }
  }
}

// KENTICO DEFAULT FORM FIELDS ONLY
textarea {
  &.form-control {
    @include border-radius(main);
    height: 120px;
  }
}

.editing-form-control-nested-control {
  select {
    background-image: url('../../../wwwroot/src/img/svg/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: calc(100% - 20px) center;
    @include border-radius(input);
  }

  iframe {
    @include mq($until: mobile-l) {
      transform: scale(0.87);
      transform-origin: 0 0;
    }
  }
}

.ktc-uploader-text {
  margin-top: 10px;
  color: $brand-black;
}

.ktc-uploader-replacement {
  color: $brand-black;
  display: block;
}

.ktc-uploader-text-button {
  margin-top: 10px;
  display: block;
  color: $brand-black;
  cursor: pointer;
}

input[type='submit'] {
  --color-bg: #{$brand-orange};
  --color-bg-hover: #{$brand-orange-60};
  --color-bg-active: #{$brand-orange-80};
  --color-text: #{$brand-white};
  --color-text-hover: #{$brand-white};
  --color-text-active: #{$brand-white};
  --color-border: #{$brand-orange};
  --color-border-hover: #{$brand-orange-60};
  --color-border-active: #{$brand-orange-80};

  &.btn--white-border {
    --color-border: #{$brand-white};
  }

  @include helpers-reset-button;
  appearance: none;
  display: inline-block;
  font-family: $brand-font-primary;
  font-size: 16px;
  @include font-weight(medium);
  text-align: center;
  text-decoration: none;
  padding-top: 15px;
  padding-right: 47px;
  padding-bottom: 15px;
  padding-left: 47px;
  border: 1px solid;
  border-radius: 24px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, color 0.3s ease-in-out;
  background-color: var(--color-bg);
  border-color: var(--color-border);
  color: var(--color-text);

  &:hover {
    background-color: var(--color-bg-hover);
    border-color: var(--color-border-hover);
    color: var(--color-text-hover);
  }

  &:active {
    background-color: var(--color-bg-active);
    border-color: var(--color-border-active);
    color: var(--color-text-active);
  }
}

// Text block - no input
.form-control__text-block {
  color: $brand-white;

  p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0;

    & + p {
      margin-top: 16px;
    }
  }
}
