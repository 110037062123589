@import '~sass-mq/mq';
// Enable media queries support
$mq-responsive: true;

// Defines breakpoints list
$mq-breakpoints: ( mobile: 375px,
                   mobile-m: 430px,
                   mobile-l: 550px,
                   tablet: 768px,
                   laptop-s: 1024px,
                   laptop-m: 1190px,
                   desktop-s: 1366px,
                   desktop: 1494px,
                   desktop-w: 1920px,
                   footer: 1080px, );

$mobile-menu-breakpoint: laptop-s;

// Padding on either side of the main container.
$site-wide-padding: 30px;

// Width of the main container.
$desktop-width: map-get($mq-breakpoints, 'desktop');
$site-width: $desktop-width + ($site-wide-padding * 2);

$layer-root: -1;
$layer-canvas: 2;
$layer-overlay: 100;

// add any site specific layer values
$layer-cookies: 110;
$layer-nav: 99;
$layer-subnav-wrapper: 91;
$layer-subnav: 90;


// colors 2022
$brand-white: #FFFFFF;
$brand-black: #000000;

$brand-orange: #F05A22; // primary button
$brand-orange-60: #C64300; // primary button hover
$brand-orange-80: #6A2000; // primary button visited

$brand-yellow: #FDB600;
$brand-yellow-30: #FFBA31;

$brand-violet: #7F09B7;
$brand-violet-10: #F7F1FF;
$brand-violet-20: #EBD8FF;
$brand-violet-30: #DBB8FF; // not used yet
$brand-violet-60: #AF00FA;
$brand-violet-80: #5D0088;

$brand-red: #E10007;
$brand-red-50: #FF4547; // error

$brand-rose: #D90074;
$brand-rose-50: #FF3691;

$brand-xperience-violet: #7F09B7;
$brand-xperience-violet-60: #AF00FA;

$brand-majorelle-blue: #4000AD;
$brand-majorelle-blue-20: #E0DCFF;

$brand-ultramarine-blue: #3D5DFF;
$brand-ultramarine-blue-50: #5984FF;

$brand-sky-blue: #00B3FC;
$brand-sky-blue-20: #C6E3FF;
$brand-sky-blue-10: #EBF5FF;

$brand-kontent-turquoise: #2CD5C4;

$brand-neon-green: #96DA00;
$brand-neon-green-40: #7EB900;

$brand-warm-grey-10: #F5F3F2;
$brand-warm-grey-30: #D1C4BF; // primary button disabled, ghost button
$brand-warm-grey-50: #766F6C;
$brand-warm-grey-80: #FF4547;

// colors transparent
$brand-orange-transparent-10: rgba($brand-orange, .10);
$brand-white-transparent-20: rgba($brand-white, .20);
$brand-black-transparent-20: rgba($brand-black, .20);

// Old colors
//$brand-black: hsl(345, 6%, 13%);
$brand-black-80: hsl(345, 6%, 20);
$brand-black-70: hsl(345, 6%, 30);
$brand-black-60: hsl(345, 6%, 40);
$brand-black-50: hsl(345, 6%, 50);
$brand-black-40: hsl(345, 6%, 60);
$brand-black-30: hsl(345, 6%, 70);
$brand-black-25: hsl(345, 6%, 75);
$brand-black-20: hsl(345, 6%, 80);
$brand-black-10: hsl(345, 6%, 90);
$brand-black-05: hsl(330, 8%, 95%);
$brand-black-02: hsl(0, 0%, 98%);

$brand-grey-30: #e3e3e3;

$brand-blue: #3d5dc0;
$brand-blue-05: rgba($brand-blue, 0.05);
$brand-blue-dark: darken(desaturate($brand-blue, 1.78), 8.04);
$brand-blue-10: #F4F5FD;

$brand-green: #30a889;
$brand-green-dark: darken(desaturate($brand-green, 1.78), 8.04);

$brand-orange-dark: darken(desaturate($brand-orange, 1.78), 8.04);

$brand-purple: #8947a6;
$brand-purple-dark: darken(desaturate($brand-purple, 1.78), 8.04);


$brand-bronze: #b07d2f;
$brand-silver: #007dbb;
$brand-gold: #fbb900;

$brand-text: $brand-black;

// form
$form-error-text: $brand-red-50;

// shadows
$brand-shadow: rgba(0, 0, 0, 0.16);
$brand-shadow-20: rgba(0, 0, 0, 0.2);

// card
$card-hover-move: translateY(-5px); // obsolete
$card-hover-shadow: 0 0 25px 0 $brand-shadow-20; // obsolete
$card-transition: transform 0.3s, box-shadow 0.5s, background-color 0.3s ease-in-out;
$card-bg: $brand-violet-10;
$card-hover-bg: $brand-violet-20;

// header
$header-shadow: 0 8px 32px rgba(16, 33, 60, .25), 0 0 8px rgba(0, 0, 0, .03);
$header-transition: box-shadow .2s ease-in-out;

// persona
$persona-shadow-on-dark: $card-hover-shadow;

// tag
$tag-padding: 8px 16px;

$brand-font-primary: 'GT Walsheim', Helvetica, Arial, sans-serif;

$font-weight: ( light: 100, regular: 400, medium: 500, bold: 700, );

$border-radius-value: ( main: 40px, double: 32px, semi: 24px, base: 16px, small: 8px, smallTop: 16px 8px 0 0, large: 8px 24px 8px 24px, medReverse: 8px 24px, checkbox: 8px, input: 24px, metrics: 24px 24px 16px 16px, cardImage: 40px, menuImage: 16px );

$paragraph-max-width: 690px;
$paragraph-margin-bottom: 28px;

$soty-button-wrapper-shadow: $card-hover-shadow;

$header-height: 72px;
$header-height-desktop: 101px;
$subnav-height: 49px;
