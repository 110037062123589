// styles relating to landscape type cards
.card-landscape {
  width: 100%;
  background-color: $brand-white;

  .card-landscape__image {
    width: 100%;
    height: 215px;

    img {
      @include object-fit(cover, center);
    }
  }
}
