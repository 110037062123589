@import '../settings/_vars.scss';

.card--blog {
  .circles {
    bottom: 0;
    right: 0;
    width: 162px;
    height: 173px;
    color: $brand-white;

    .circle {
      bottom: 0;
      right: 0;
    }
  }
}

.card__media {
  .card--blog & {
    min-height: 240px;
    background-color: var(--color-bg);
  }
}

.card__media-element {
  .card--blog.card--landscape & {
    @include mq(laptop-s) {
      min-height: 487px;
    }
  }
}

.card__content-external {
  .card--landscape.card--blog & {
    @include mq(tablet) {
      align-content: flex-start;
      display: grid;
      grid-template-rows: auto 1fr;
    }
  }
}

.card__content-internal {
  .card--landscape.card--blog & {
    @include mq(tablet) {
      display: grid;
      grid-template-rows: auto 1fr auto;
    }

    @include mq(laptop-s) {
      padding-right: 140px;
    }
  }

  .card__blog-detail {
    font-size: 14px;

    .bullet {
      display: inline-block;
      margin-left: 4px;
      margin-right: 4px;
    }
  }
}

.card__title {
  .card--landscape.card--blog & {
    @include mq(laptop-s) {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.card__blog-detail {
  color: $brand-black-50;
  line-height: 16px;

  .card--featured & {
    color: var(--color-text-featured);
  }
}
// AUTHOR STYLES
.card__author {
  display: grid;
  grid-template-columns: 42px auto;
  margin-bottom: 20px;
  align-content: flex-start;
}

.card__author-img {
  width: 32px;
  height: 32px;
  overflow: hidden;
  border-radius: 32px;
}

.card__author-element {
  @include object-fit(cover, center);
  width: 32px;
  height: 32px;
}

.card__author-name {
  align-self: center;
  text-transform: capitalize;
}
