@import '../../settings/_vars.scss';

.form-clickable {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &[disabled] {
    cursor: not-allowed;

    + .form-label {
      cursor: not-allowed;
      color: $brand-black-20;

      &:before {
        border-color: $brand-black-25;
      }
    }
  }

  &:hover:not([disabled]) + .form-label {
    color: $brand-blue;

    &:before {
      border-color: $brand-blue;
    }
  }

  &:checked + .form-label,
  &:checked + input[type='hidden'] + .form-label {
    &:before {
      border-color: $brand-red-50;
      background-color: $brand-red-50;
    }

    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }

  &.form-clickable--checkbox {
    + .form-label,
    + input[type='hidden'] + .form-label {
      &:before {
        @include border-radius(checkbox);
      }

      &:after {
        width: 16px;
        height: 16px;
        background-color: transparent;
        background-image: url('../../../wwwroot/src/img/svg/icon-tick.svg');
        top: 6px;
        left: 5px;
      }
    }
  }

  &.form-clickable--error:not(:checked) {
    + .form-label {
      color: $form-error-text;
      padding-right: 30px;
      background-image: url('../../../wwwroot/src/img/svg/icon-error.svg');
      background-repeat: no-repeat;
      background-position: right 6px;
      background-size: 16px 16px;

      &.form-clickable--error-white {
        color: $brand-white;

        &::before {
          border-color: $brand-white;
        }
      }

      &:before {
        border-color: $form-error-text;
      }

      + .form-label--error {
        display: block;
        color: $form-error-text;
        flex: 1 0 auto;

        &::before,
        &:after {
          content: none;
        }

        &.form-label--error-background {
          background: $brand-white;
          border-radius: 4px;
          color: $form-error-text;
          padding: 0.75rem;
          max-width: 100%;

          &:hover {
            color: $form-error-text;
          }
        }
      }
    }
  }
}

.bg-violet,
.bg-black,
.bg-blue,
.cta--black,
.form-orange {
  .form-clickable {
    &:hover:not([disabled]) + .form-label {
      color: $brand-red-50;

      &:before {
        border-color: $brand-red-50;
      }
    }

    &:checked + .form-label {
      &:before {
        border-color: $brand-red-50;
        background-color: $brand-red-50;
      }
    }
  }
}

.bg-violet,
.bg-black,
.bg-blue,
.cta--black {
  .form-clickable {
    &:hover:not([disabled]) + .form-label {
      color: $brand-white;
    }
  }
}

// KENTICO DEFAULT FORM FIELDS ONLY
.ktc-checkbox {
  &:hover {
    label {
      &:before {
        border-color: $brand-orange;
      }
    }
  }

  input[type='checkbox'] {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:checked {
      + label,
      + input + label {
        &:before {
          border-color: $brand-red-50;
          background-color: $brand-red-50;
        }

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  label {
    order: 2;
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding-top: 2px;
    padding-left: 40px;
    transition: color 0.3s ease-out;

    a {
      color: $brand-white;
    }

    &:before {
      @include border-radius(checkbox);
      width: 24px;
      height: 24px;
      content: '';
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border: 1px solid $brand-white;
      flex-shrink: 0;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
      position: absolute;
      top: 1px;
      left: 0;
    }

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      background-color: transparent;
      background-image: url('../../../wwwroot/src/img/svg/icon-tick.svg');
      position: absolute;
      top: 6px;
      left: 5px;
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    }
  }
}

.ktc-checkbox-list .ktc-checkbox {
  margin-bottom: 0.5rem;
}

.ktc-radio {
  .ktc-radio:hover {
    label {
      &:before {
        border-color: $brand-red-50;
      }
    }
  }

  input[type='radio'] {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;

    &:checked {
      + label,
      + input + label {
        &:before {
          border-color: $brand-blue;
          background-color: $brand-blue;
        }

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  label {
    margin-bottom: 5px;
    order: 2;
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 24px;
    padding-top: 2px;
    padding-left: 40px;
    transition: color 0.3s ease-out;

    a {
      color: $brand-white;
    }

    &:before {
      width: 24px;
      height: 24px;
      content: '';
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border-radius: 24px;
      border: 1px solid $brand-black-50;
      flex-shrink: 0;
      transition: background-color 0.3s ease-out, border-color 0.3s ease-out;
      position: absolute;
      top: 1px;
      left: 0;
    }

    &:after {
      width: 12px;
      height: 12px;
      content: '';
      position: absolute;
      top: 8px;
      left: 7px;
      background-color: $brand-white;
      border-radius: 12px;
      opacity: 0;
      transform: scale(0);
      transition: opacity 0.3s ease-out, transform 0.3s ease-out;
    }
  }
}

.ktc-radio-list--inline {
  .ktc-radio-desc {
    padding: 16px 0;
    color: $brand-black-20;
    font-size: 0.8125rem;
  }
}

@include mq(mobile-l) {
  .ktc-radio-list--inline {
    display: flex;
    width: 100%;
    height: 100%;

    .ktc-radio {
      flex-basis: 100%;
      text-align: center;
    }

    .ktc-radio-desc {
      padding: 32px 0 0;
    }
  }
}

.bg-violet,
.bg-black,
.cta--black,
.form-orange {
  .ktc-checkbox input[type='checkbox'],
  .ktc-radio input[type='radio'] {
    &:checked {
      + label,
      + input + label {
        &:before {
          border-color: $brand-red-50;
          background-color: $brand-red-50;
        }

        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.control-label {
  + .editing-form-control-nested-control {
    .ktc-radio {
      display: block;
      margin-top: 12px;

      label {
        font-size: 16px;
        line-height: 24px;

        &:before {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }

        &:after {
          top: 6px;
          left: 5px;
        }
      }
    }

    @include mq(mobile-l) {
      .ktc-radio-list--inline {
        .ktc-radio {
          label {
            padding: 0;
            font-size: 0.75rem;

            &:before {
              left: 40%;
              top: 30px;
            }

            &:after {
              top: 35px;
              left: 45%;
            }
          }
        }
      }
    }

    .ktc-checkbox-list {
      display: block;
      margin-top: 12px;
    }
  }
}
