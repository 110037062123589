.card {
  &.card--events-featured {
    position: relative;
    margin-left: 15px;
    margin-right: 15px;
    overflow: visible;
    border-radius: 0;
    box-shadow: none;

    @include mq(laptop-s) {
      margin-top: 80px;
    }

    &:hover {
      transform: translateY(0);
    }
  }
}

.card__inner {
  .card--events-featured & {
    display: grid;
  }
}

.card__media {
  .card--events-featured & {
    @include border-radius(main);
    overflow: hidden;
    position: relative;
    transform: translateX(-15px);
    z-index: 1;

    @include mq(laptop-s) {
      transform: translateX(0);
      position: absolute;
      left: 0;
      width: 49%;
      margin-bottom: 0;
    }
  }

  .card--events-featured.card--flipped & {
    @include mq(laptop-s) {
      left: auto;
      right: 0;
    }
  }
}

.card__content-external {
  .card--events-featured & {
    @include border-radius(main);
    width: 100%;
    background: none;

    @include mq(laptop-s) {
      width: calc(51% + 108px);
      justify-self: flex-end;
      padding-left: 139px;
      display: grid;
      grid-template-rows: auto 1fr;
      overflow: hidden;
    }
  }

  .card--events-featured.card--flipped & {
    @include mq(laptop-s) {
      justify-self: flex-start;
      padding-left: 0;
      padding-right: 139px;
    }
  }

  .card__fake-link {
      margin-top: 32px;
      text-decoration: underline;
      text-transform: uppercase;
  }

  .tag {
    .card--events-featured & {
      position: absolute;
      transform: translateY(-50%);
      justify-self: flex-start;
      align-self: flex-end;
      left: 30px;
      z-index: 2;

      @include mq(laptop-s) {
        transform: translateY(0);
        top: auto;
        left: 0;
        position: relative;
      }
    }

    .card--events-featured.card--flipped & {
      @include mq(laptop-s) {
        left: 80px;
      }
    }
  }
}

.card__content-internal {
  .card--events-featured & {
    padding: 40px 30px;

    @include mq(laptop-s) {
      display: grid;
      grid-template-rows: auto 1fr auto;
      padding: 40px 80px 40px 0;
    }
  }

  .card--events-featured.card--flipped & {
    @include mq(laptop-s) {
      padding: 40px 0 40px 80px;
    }
  }
}

.card__title {
  .card--events-featured & {
    @include mq(laptop-s) {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.card__event-date-area {
  .card--events-featured & {
    @include mq(laptop-s) {
      padding-right: 180px;
    }
  }
}

.card__event-date {
  @include letter-spacing(20);
  align-self: center;
  font-size: 14px;
  line-height: 16px;
  color: $brand-black-30;
}
