/**
* Used to disable the card transition on Kentico.com only as it
* conflicts with framer-motion's animation.
*/
.no-transition {
  transition: none !important;
}

@mixin anim-circle-pulse($anim-duration: 10s) {
  animation: circles-pulse infinite linear;
  animation-duration: $anim-duration;

  &:nth-child(1) {
    animation-delay: $anim-duration * 0;
  }

  &:nth-child(2) {
    animation-delay: $anim-duration * 0.2;
  }

  &:nth-child(3) {
    animation-delay: $anim-duration * 0.4;
  }

  &:nth-child(4) {
    animation-delay: $anim-duration * 0.6;
  }

  &:nth-child(5) {
    animation-delay: $anim-duration * 0.8;
  }
}

@mixin anim-radial-timer($anim-duration: 10s) {
  animation: radial-timer $anim-duration linear forwards;
}

@keyframes circles-pulse {
  0% {
    opacity: 1;
    transform: translate(50%, 50%) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate(50%, 50%) scale(1);
  }
}

@keyframes radial-timer {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes up-down-anim {
  0% {
    transform: translateY(-12px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(-12px);
  }
}
