@import '../settings/_vars.scss';
$card-vertical-indent: 40px;
$card-horizontal-indent: 30px;

.card {
  --color-bg: #{$card-bg};
  --color-bg-featured: #{$brand-violet};
  --color-bg-hover: #{$brand-white};
  --color-text: #{$brand-text};
  --color-text-featured: #{$brand-black};
  --color-text-featured-hover: #{$brand-black};

  @include border-radius(main);
  position: relative;
  display: block;
  color: $brand-text;
  height: 100%;
  overflow: hidden;
  text-decoration: none;
  transform: translateY(0);
  transition: $card-transition;

  .grid-container--six-two > .grid-column:nth-child(2) &,
  .grid-container--two-six > .grid-column:first-child & {
    height: auto;

    & + .card {
      margin-top: 40px;
    }
  }

  &.card--no-img {
    overflow: visible;

    .circle-animation {
      display: none;
    }
  }

  @include mq(tablet) {
    &--align-left {
      text-align: left;
    }

    &--align-right {
      text-align: right;
    }
  }

  .fake-link {
    margin-top: 20px;
  }

  .circle-animation {
    color: $brand-black;
  }

  &--featured,
  &--event {
    .circle-animation {
      color: $brand-violet-60;
    }
  }
}

// hover only if the card is wrapped in link
a.card:not(.card--event):hover {
  .card__content-external {
    background-color: $card-hover-bg;

    .card__title {
      color: var(--color-text-featured-hover);
    }
  }
}

.card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .card--landscape & {
    @include mq(tablet) {
      grid-template-rows: auto;
      grid-template-columns: auto 1fr;
    }
  }

  .card--landscape & {
    display: grid;
  }
}

.card__media {
  min-height: 230px;
  background-color: var(--color-bg);

  + .card__content-external {
    &:before {
      content: none;
    }

    .tag {
      top: 0;
    }
  }
}

.card__media-holder {
  line-height: 0;
  height: 100%;

  .card--no-img & {
    display: none;
  }
}

.card__media-element {
  @include object-fit(cover, center);
  width: 100%;
  height: 240px;

  .card--landscape & {
    @include mq(tablet) {
      height: 100%;
      width: 240px;
    }

    @include mq(laptop-s) {
      width: 372px;
    }
  }

  .card--small-ver & {
    height: 240px;
  }
}

.card__content-external {
  position: relative;
  background-color: var(--color-bg);
  flex: 1 1 auto;
  height: auto;
  transition: background-color .35s ease-in-out;

  &::before {
    content: '';
    width: 100%;
    height: 240px;
    background-color: var(--color-bg);
    display: block;
  }

  .card--no-img & {
    @include border-radius(main);

    &::before {
      content: none;
    }

    .tag {
      top: 0;
    }
  }

  .card--landscape & {
    @include mq(tablet) {
      padding: 40px 0;
      display: grid;
      align-content: center;
    }

    &::before {
      content: none;
    }
  }

  .card--featured &,
  .card--events-featured &,
  .card--stat & {
    &::before {
      content: none;
    }
  }

  .tag {
    position: absolute;
    left: 30px;
    top: 240px;
    transform: translateY(-50%);

    @include mq(tablet) {
      left: 45px;
    }

    .card--landscape & {
      @include mq(tablet) {
        position: relative;
        transform: translateY(0);
        justify-self: flex-start;
        align-self: flex-end;
      }
    }
  }
}

.card__content-internal {
  padding: $card-vertical-indent $card-horizontal-indent;

  @include mq(tablet) {
    padding: 40px 45px;
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
  }

  .card--no-img & {
    padding: 40px 70px 40px 30px;

    @include mq(tablet) {
      padding: 40px 70px 40px 45px;
    }
  }

  .card--landscape & {
    @include mq(tablet) {
      padding: 25px 45px;
      display: block;
      height: auto;
    }
  }

  p {
    @include letter-spacing(20);
    font-size: 16px;

    &.card__event-date {
      font-size: 14px;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  .card--event & {
    color: var(--color-text-featured);
  }
}

.card__title {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 15px;

  .card--small-ver & {
    font-size: 22px;
    line-height: 26px;
  }
}

.h2-styling.card__title,
h2.card__title,
.h3-styling.card__title,
h3.card__title,
.h4-styling.card__title,
h4.card__title {
  &:after {
    font-size: 22px;
  }
}

.card__price {
  display: inline-block;
  margin-top: 8px;
  @include font-weight(bold);
  font-size: 18px;
  line-height: 24px;
  color: $brand-text;

  &--stressed {
    font-size: 24px;
    line-height: 28px;
  }
}
