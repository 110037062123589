.course-carousel {
  display: block;
  margin: 80px 0;
  overflow: hidden;

  &__header {
    margin: 0 auto 52px;
    max-width: 800px;
    text-align: center;
  }

  &__inner {
    position: relative;
    padding: 48px 0 64px;
  }

  @include mq(laptop-s) {
    @include fade-mask(10%);

    .tns-ovh {
      overflow: visible;
    }
  }

  @include mq(laptop-m) {
    @include fade-mask(15%);
  }

  &__track {
    display: flex;
  }

  &__item {
    max-width: 372px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    .cms-edit & {
      display: inline-block;

      &:nth-child(n + 4) {
        display: none;
      }
    }

    .card {
      width: 100%;
      max-width: 315px;

      @include mq(tablet) {
        max-width: 372px;
      }
    }
  }

  &__controls {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__control {
    @include bs(0 0 0 0);
    display: inline-flex;
    height: 32px;
    width: 32px;
    align-items: center;
    background: $brand-white;
    box-shadow: none;
    border: 2px solid $brand-black-05;
    border-radius: 50%;
    color: $brand-orange;
    font-size: 0;
    justify-content: center;
    margin: 4px;
    outline: none;
    padding: 0;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
      box-shadow 0.3s ease-in-out, color 0.3s ease-in-out, transform 0.3s ease-in-out;
    transform: scale(1);

    svg {
      display: block;
      height: 14px;
      width: 14px;
      stroke: $brand-orange;
      transition: stroke 0.3s ease-in-out;
    }

    &--prev {
      svg {
        transform: rotate(90deg);
      }
    }

    &--next {
      svg {
        transform: rotate(-90deg);
      }
    }

    &:focus,
    &:hover {
      @include bs(0 0 10px 0);
      background: $brand-orange;
      border-color: $brand-orange;
      color: $brand-white;
      transform: scale(1.1);

      svg {
        stroke: $brand-white;
      }
    }
  }

  // EDGE carousel fix
  .tns-item {
    margin-left: 0 !important;
  }

  // EDGE carousel fix
  .tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
    margin-right: 0 !important;
  }

  .tns-nav {
    @include bs(0 0 0 0);
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: auto;
    justify-content: center;
    width: 100%;

    [data-nav] {
      display: inline-block;
      height: 16px;
      width: 16px;
      background: none;
      border: 4px solid $brand-black-05;
      border-radius: 50%;
      margin: 5px;
      outline: none;
      padding: 0;
      transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
        color 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: scale(1);

      &:focus,
      &:hover,
      &.tns-nav-active {
        @include bs(0 0 6px 0);
        border-color: $brand-orange;
        transform: scale(1.2);
      }
    }
  }
}
