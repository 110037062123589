@import '../settings/_vars.scss';

.project-card {
  height: 100%;
  background: $brand-white;
  @include border-radius(small);
  text-decoration: none;
  display: block;
  overflow: hidden;
  transform: translateY(0);
  transition: transform 0.3s, box-shadow 0.5s;

  &:hover {
    transform: translateY(-5px);
  }

  &.project-card--partner {
    @include border-radius(main);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0);

    &:hover {
      box-shadow: $card-hover-shadow;
    }
  }
}

.project-card__inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.project-card__media {
  + .project-card__content-external {
    // Add space when there isn't a middle band
    padding-top: 56px;
  }
}

.project-card__media-holder {
  position: relative;
  height: 0;
  line-height: 0;
  // Using intrinsic ratio as project images are 4:3
  padding-bottom: 75%;
}

.project-card__media-element {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  width: 100%;
}

.project-card__middle-band {
  background-color: $brand-black;
  padding: 15px 35px;

  .project-card--partner & {
    background-color: $brand-black-05;
    padding: 16px 35px 8px 35px;
  }
}

.project-card__middle-content {
  display: grid;
  align-items: center;
  color: $brand-white;
  font-size: 22px;
  @include font-weight(medium);
  grid-template-columns: min-content 1fr;
  line-height: 28px;
  margin: 0;

  .project-card--partner & {
    color: $brand-text;

    .kentico-circle-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    p {
      display: inline-block;
      color: $brand-black-70;
      font-size: 16px;
      @include font-weight(regular);
      line-height: 18px;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
    }

    span {
      @include font-weight(medium);
      color: $brand-text;
    }
  }
}

.project-card__content-external {
  background-color: $brand-white;
  flex: 1 0 auto;

  .project-card--partner & {
    background-color: $brand-black-05;
  }
}

.project-card__content-internal {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 20px 35px 15px;

  .project-card--partner & {
    padding-bottom: 40px;
  }

  p:last-child {
    margin-bottom: 0;
  }

  .fake-link {
    font-size: 16px;
    margin-top: auto;
  }

  .card__event-date {
    align-self: flex-start;
    color: $brand-black-50;
    margin-bottom: 16px;
  }

  .project-card__content-bottom {
    margin-top: auto;
  }
}

.project-card__title {
  @include font-weight(medium);
  margin-bottom: 3px;
  font-size: 22px;

  .project-card--partner & {
    @include font-weight(bold);
    margin-bottom: 15px;
  }
}

.project-card__intro {
  color: $brand-text;
  margin-bottom: 20px;
  font-size: 18px;

  .project-card--partner & {
    font-size: 16px;
  }
}