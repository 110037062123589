.card--webinar {
  .card__media {
    position: relative;
  }

  .card__content-external {
    min-height: 237px;
    display: flex;
  }

  .card__content-internal {
    display: flex;
    flex-direction: column;
    height: auto;

    .card__intro {
      margin-top: auto;

      span {
        display: block;
      }
    }
  }
}
