@import '../settings/vars';

.persona {
  border: 1px solid $brand-grey-30;
  @include border-radius(main);
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: $brand-white;

  & + & {
    margin-top: 40px;
  }

  .bg-light-grey & {
    box-shadow: $persona-shadow-on-dark;
  }

  .persona__media + .persona__content {
    margin-top: 32px;
  }

  &--wide {
    @include mq(mobile-l) {
      flex-direction: row;
    }

    .persona__media + .persona__content {
      margin-top: 32px;

      @include mq(mobile-l) {
        margin-top: 0;
        margin-left: 32px;
      }
    }
  }

  &--testimonial {
    .persona__content {
      display: flex;
      flex-direction: column;
    }

    .persona__text {
      order: 1;
      margin-top: 0;
      margin-bottom: 32px;

      p {
        font-size: 18px;
        font-weight: bold;
      }
    }

    .persona__name {
      order: 2;
      font-size: 16px;
      word-spacing: .36px;
    }

    .persona__job {
      order: 3;
      margin-top: 0;
    }

    .persona__position,
    .persona__company {
      font-size: 16px;
    }
  }

  &__image {
    width: 126px;
    height: 126px;
  }

  &__image-element {
    width: 126px;
    height: 126px;
  }

  &__name {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  &__position,
  &__company {
    font-size: 18px;
    line-height: 24px;
    word-spacing: .36px;
  }

  &__job {
    margin-top: 12px;
  }

  &__text {
    margin-top: 32px;

    p {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}
