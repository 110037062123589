@import '../settings/_vars.scss';

.price-card {
  position: relative;
  display: flex;
  background-color: $card-bg;
  @include border-radius(main);
  flex: 1 0 auto;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 355px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: $layer-canvas;

  &--featured {
    background-color: $brand-violet;
  }

  @include mq(laptop-s) {
    max-width: 100%;
  }

  &__top {
    display: flex;
    align-content: center;
    flex: 0 0 170px;
    flex-direction: column;
    height: 170px;
    justify-content: center;
  }

  &__top-inner {
    padding: 20px 30px;
  }

  &__title {
    @include letter-spacing(20);
    margin-bottom: 0;

    .price-card--featured & {
      color: $brand-white;

      &:after {
        color: $brand-white;
      }
    }
  }

  &__middle {
    display: flex;
    align-content: center;
    flex: 0 0 125px;
    flex-direction: column;
    height: 125px;
    justify-content: center;
  }

  &__middle-inner {
    padding: 20px 30px;
  }

  &__stats {
    @include letter-spacing(20);
    margin: 5px 0;
    color: $brand-text;
    font-size: 16px;
    line-height: 18px;

    .price-card--featured & {
      color: $brand-white;
    }

    span {
      @include font-weight(bold);
      display: block;
    }
  }

  &__bottom {
    display: flex;
    align-self: center;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;

    .btn {
      margin-top: auto;
      word-break: break-all;
      margin-bottom: 40px;
    }
  }

  &__bottom-inner {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 45px 30px;
    position: relative;

    & + & {
      padding-top: 0;
    }

    &:first-child {
      padding: 45px 30px 20px 30px;
    }

    &:only-child {
      padding: 45px 30px;
    }

    &:nth-child(2) {
      &:before {
        content: '';
        position: relative;
        width: 120px;
        height: 1px;
        background-color: $brand-black-10;
        display: block;
        margin: 0 auto 32px auto;

        .price-card--featured & {
          background-color: $brand-black-60;
        }
      }

      .price-card__price {
        font-size: 22px;
      }
    }
  }

  &__pre-price {
    @include letter-spacing(20);
    @include font-weight(medium);
    color: $brand-text;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;

    .price-card--featured & {
      color: $brand-white;
    }
  }

  &__price {
    @include letter-spacing(20);
    @include font-weight(bold);
    color: $brand-text;
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 10px;
    // commented out as breaks words on different breakpoints, left in incase its a fix for something else and needs a work around
    // word-break: break-all;

    .price-card--featured & {
      color: $brand-white;
    }
  }

  &__list {

    &.accordion__item-content-inner {
      ul {
        list-style: none;
      }
    }


    li {
      @include font-weight(medium);
      @include letter-spacing(20);
      position: relative;
      padding-bottom: 30px;
      padding-left: 20px;
      font-size: 16px;
      line-height: 21px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 7px;
        height: 7px;
        border: 1.5px solid $brand-orange;
        border-radius: 50%;
      }

      a {
        color: $brand-black;

        &:hover {
          color: $brand-orange;
        }
      }

      ul {
        margin-top: 7px;

        li {
          @include font-weight(regular);
          font-size: 14px;
          margin-bottom: 5px;
          padding: 0;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}
// Embedded accordion styles -- some of this may be better uplifted into _accordion.scs
.accordion--pricing {
  display: block;
  background-color: $brand-white;
  max-width: 355px;
  width: 100%;

  .accordion__item {
    position: relative;

    &-header {
      position: relative;
      display: block;
      background: none;
      height: 60px;
      padding: 18px 72px 18px 36px;
      outline: 0;
      border-bottom: 1px solid $brand-black-transparent-20;

      > span {
        @include ls(20);
        display: block;
        overflow: hidden;
        font-size: 16px;
        line-height: 21px;
        text-overflow: ellipsis;
        text-transform: none;
        white-space: nowrap;
      }

      &::before,
      &::after {
        content: none;
      }
    }

    &-content {
      background: none;
    }

    &-content-inner {
      padding: 18px 36px;

      ul {
          margin-bottom: 2px;
      }
    }

    .icon-svg {
      position: absolute;
      top: 20px;
      right: 36px;
      display: block;
      color: $brand-orange;
      height: 16px;
      transform-origin: center center;
      transition: transform 0.3s ease-in-out;
      width: 16px;
    }

    &.is-active {
      .icon-svg {
        transform: rotate(180deg);
      }

      .accordion__item-header,
      .accordion__item-content {
        background: none;
        border-radius: inherit;
      }
    }
  }
}
