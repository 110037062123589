@import '../settings/_vars.scss';

.cards {
  .grid-container {

    &--third {
      .grid-column {
        grid-column: span 8;
        position: relative;
        display: flex;
        justify-content: center;

        @include mq(laptop-s) {
          &:nth-child(3n + 1) {
            grid-column: 2 / span 2;
          }

          &:nth-child(3n + 2) {
            grid-column: 4 / span 2;
          }

          &:nth-child(3n + 3) {
            grid-column: 6 / span 2;
          }
        }
      }
    }
  }
}
